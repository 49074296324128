import React, {useEffect, useState} from 'react';
import style from "./Modal.module.scss"
import axios from "axios";
import Notification from "../notification/Notification";
import {useTranslation} from "react-i18next";
function Modal({top, opacity, visibility}) {
    const [isSuccesSend, setIsSuccesSend] = useState(false)
    const [isErrorSend, setIsErrorSend] = useState(false)
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        text: '',
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if(formData.name !== "" && formData.email !== "" && formData.text !== ""){
            axios.post('http://localhost:3001/send-email', formData)
                .then((response) => {
                    if(response.status === 200){
                        setIsSuccesSend(true)
                        setIsErrorSend(false)
                    }
                    setFormData({
                        name: '',
                        email: '',
                        text: '',
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        }else {
            setIsErrorSend(true)
            setIsSuccesSend(false)
        }
    };
    useEffect(() => {
        if(isSuccesSend){
            setTimeout(() => {
                setIsSuccesSend(false)
                setIsErrorSend(false)
            }, 3000)
        }
    }, [isSuccesSend, isErrorSend])
    return (
        <div className="container">
            <div className={style.modal} style={{top: top, transition: ".3s", opacity: opacity, visibility: visibility}}>
                <div className={style.modal_title}>{t("Write your message")}</div>
                <div className={style.modal_body}>
                    <div className={style.modal_input}>
                        <label htmlFor="name">{t("Name")}</label>
                        <input type="text" name="name" id="name" required value={formData.name} onChange={handleInputChange}/>
                    </div>
                    <div className={style.modal_input}>
                        <label htmlFor="email">{t("Email")}</label>
                        <input type="email" name="email" id="email" required value={formData.email} onChange={handleInputChange}/>
                    </div>
                    <div className={style.modal_input}>
                        <label htmlFor="">{t("Message")}</label>
                        <textarea
                            name="text" id="text" cols="30" rows="10" value={formData.text} onChange={handleInputChange}
                        ></textarea>
                    </div>
                    <div className={style.modal_footer}>
                        <button className="button" onClick={handleSubmit}><span>{t("Send")}</span></button>
                        {isSuccesSend && <Notification notification={"Thank you for contacting us, we will contact you!"} bgColor="2fb500"/> }
                        {isErrorSend && <Notification notification={"Fill in all the fields!"} bgColor="d50202"/> }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;