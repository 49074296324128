import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isSnow: false
}

const snowSlice = createSlice({
    name: 'snowSlice',
    initialState,
    reducers: {
        Snow:(state, action) => {
            state.isSnow = action.payload
        }
    }
})

export default snowSlice.reducer;

export const {Snow} = snowSlice.actions