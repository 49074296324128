import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {api} from "../rtkquery/api";
import adminSlice from "../adminSlice";
import snowSlice from "../snowSlice";

const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    adminSlice: adminSlice,
    snow: snowSlice,
})
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware), // Добавьте middleware
});
