import React, {useEffect, useState} from 'react';
import style from "./Head.module.scss"
import Logo from "../logo/Logo";
import Link from "../link/Link";
import {useTranslation} from "react-i18next";
import useLocalStorage from "../../hooks/use-localstorage";
import i18n from "i18next";
import Modal from "../modal/Modal";
import DarkBg from "../dark-bg/DarkBG";
import Languages from "../language/Languages";

function Head() {
    const [isOpenMenu, setIsOpenMenu] = useState(false)
    const {t} = useTranslation();
    const [modalOpen, setModalOpen] = useState(false)
    const handleOpen = () => {
        setIsOpenMenu(!isOpenMenu)
    }

    const handleModalOpen = () => {
        setModalOpen(true)
    }
    const handleModalClose = () => {
        setModalOpen(false)
    }

    useEffect(() => {
        const links = document.querySelectorAll('a[href^="#"]');
        links.forEach((link) => {
            link.addEventListener('click', (e) => {
                e.preventDefault();
                const targetId = link.getAttribute('href').substring(1);
                const targetElement = document.getElementById(targetId);
                if (targetElement) {
                    window.scrollTo({
                        top: targetElement.offsetTop,
                        behavior: 'smooth',
                    });
                }
            });
        });
    }, []);
    return (
        <div className={style.head}>
            <Modal top={modalOpen ? 20 : 0} visibility={modalOpen ? "visible" : "hidden"} opacity={modalOpen ? 1 : 0}/>
            {modalOpen &&
                <DarkBg func={handleModalClose}/>
            }
            <div className="container">
                <div className={style.head_body}>
                    <Logo/>
                    <div className={isOpenMenu ? style.show + " " + style.links_body : style.links_body}>
                        <Link linkText={t("About me")} sectionID="about-me"/>
                        <Link linkText={t("Works")} sectionID="my-works"/>
                        <Link linkText={t("Skills")} sectionID="skills"/>
                        <Link linkText={t("Contacts")} sectionID="contacts"/>
                        <button className="button" onClick={handleModalOpen}><span>{t("Сontact with me")}</span></button>
                        <Languages/>
                    </div>
                    <button className={isOpenMenu ? style.burger_click + " " + style.head_burger : style.head_burger}
                            onClick={handleOpen}><span></span></button>
                </div>
            </div>
        </div>
    );
}

export default Head;