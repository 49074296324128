import React from 'react';
import happy_new_year_and_merry_cristmass from "../../assets/images/happy-new-year-and-merry-cristmas.png"
import snowMan from "../../assets/images/snow-man.png"
import style from "./SnowInGround.module.scss"
export default function SnowInGround() {
    return (
        <div className={style.snow}>
            <div className={style.snow_image}></div>
            <div className={style.happy_new_year_and_merry_cristmass}><img src={happy_new_year_and_merry_cristmass} alt=""/></div>
            <div className={style.snow_man}><img src={snowMan} alt=""/></div>
        </div>
    )
}