import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {store} from "./redux/store/store"
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {I18nextProvider} from "react-i18next";
import i18n from './i18n';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <App />
            </Provider>
        </I18nextProvider>
    </BrowserRouter>
);
