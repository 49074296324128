import React, {useState} from 'react';
import style from "./Admin.module.scss"
import Logo from "../components/logo/Logo";
import {
    useAddDataMutation, useAddSkillsMutation, useChangeLoginDataMutation, useDataCheckingQuery,
    useDeleteDataMutation,
    useFetchDataQuery, useGetIsSnowQuery, useGetSkillsQuery, useIsSnowMutation
} from "../redux/rtkquery/api";
import {useDispatch, useSelector} from "react-redux";
import {
    ChangeLogin,
    ChangePassword, ImgAlt,
    LinkWork,
    Name,
    OldLogin, SkillsSource,
    Technology,
    WorkImage
} from "../redux/adminSlice";
import {BiSolidTrash} from "react-icons/bi";
import ImageUploader from "../components/image-uploader/ImageUploader";
import PasswordInAdmin from "../components/password-in-admin-panel/PasswordInAdmin";
import Notification from "../components/notification/Notification";
import {Snow} from "../redux/snowSlice";
function Admin() {
    const [isAllWorks, setIsAllWorks] = useState(true)
    const [addNewWork, setAddNewWork] = useState(false)
    const [changeData, setChangeData] = useState(false)
    const [skills, setSkills] = useState(false)
    const [addWork] = useAddDataMutation()
    const [deleteWork] = useDeleteDataMutation()
    const {data = []} = useFetchDataQuery()
    const dispatch = useDispatch()
    const {name, technology, image, link, isLock, changeLogin, changePassword, oldLogin, skillsSource, imgAlt} = useSelector(state => state.adminSlice)
    const isSnow = useSelector(state => state.snow.isSnow)
    const {data: snowData} = useGetIsSnowQuery()
    const defaultAddress = window.location.protocol + "//" + link + "." + window.location.host;
    const [changeLoginData] = useChangeLoginDataMutation()
    const [snowMode] = useIsSnowMutation()
    const [isSuccesSend, setIsSuccesSend] = useState(false)
    const [isErrorSend, setIsErrorSend] = useState(false)
    const [isErrorOldLoginSend, setIsErrorOldLoginSend] = useState(false)
    const {data: loginData} = useDataCheckingQuery()
    const {data: getSkills} = useGetSkillsQuery()
    const [addSkills] = useAddSkillsMutation()

    const handleAllWorks = () => {
        setIsAllWorks(true)
        setAddNewWork(false)
        setChangeData(false)
        setSkills(false)
    }
    const handleAddNewWork = () => {
        setIsAllWorks(false)
        setChangeData(false)
        setAddNewWork(true)
        setSkills(false)
    }
    const handleOpenChangeAdminSignIn = () => {
        setChangeData(true)
        setIsAllWorks(false)
        setAddNewWork(false)
        setSkills(false)
    }
    const handleSkillBlock = () => {
        setChangeData(false)
        setIsAllWorks(false)
        setAddNewWork(false)
        setSkills(true)
    }
    const handleAddWork = async () => {
        await addWork(
            {
                name,
                technology,
                link,
                image
            }
        )
        dispatch(Name(""))
        dispatch(Technology(""))
        dispatch(LinkWork(""))
        dispatch(WorkImage(""))
    }
    const handleDeleteWork = async (id) => {
        await deleteWork({id})
    }
    const handelChangeAdminSignIn = async () => {
        const dbOldLogin = loginData[0].login
        const data = {
            changeLogin,
            changePassword
        }
        if(changePassword && changeLogin && oldLogin === dbOldLogin){
            await changeLoginData(data)
            dispatch(ChangeLogin(""))
            dispatch(ChangePassword(""))
            dispatch(OldLogin(""))
            setIsErrorSend(false)
            setIsSuccesSend(true)
            setIsErrorOldLoginSend(false)
        }else if(oldLogin !== dbOldLogin){
            setIsErrorOldLoginSend(true)
            setIsErrorSend(false)
            setIsSuccesSend(false)
        }else{
            setIsErrorSend(true)
            setIsSuccesSend(false)
        }
    }

    const handleSkill = async () => {
        const data = {
            skillsSource,
            skillIMG: image,
            imgAlt
        }
        await addSkills(data)
        dispatch(WorkImage(""))
        dispatch(SkillsSource(""))
    }

    const handleIsSnow = async () => {
        const data = {
            snow: snowData[0].isSnow === 1 ? 0 : 1
        }
        await snowMode(data)
    }
    return (
        <div className={style.admin}>
            {isLock
                ?
                <>
                    <div className={style.admin_panel}>
                        <Logo/>
                        <button className={style.admin_btn} onClick={handleAllWorks}>Все работы</button>
                        <button className={style.admin_btn} onClick={handleAddNewWork}>Добавить</button>
                        <button className={style.admin_btn} onClick={handleOpenChangeAdminSignIn}>Сменить логин и пароль</button>
                        <button className={style.admin_btn} onClick={handleIsSnow}>Зимний режим {isSnow ? "откл." : "вкл."}</button>
                        <button className={style.admin_btn} onClick={handleSkillBlock}>Добавить скил</button>
                    </div>
                    <div className={style.admin_content}>
                        {isAllWorks &&
                            <table className={style.all_works}>
                                {data.length > 0 ?
                                    <>
                                        <thead>
                                        <tr>
                                            <th>Картинка</th>
                                            <th>Название</th>
                                            <th>Технологии</th>
                                            <th>Ссылка</th>
                                            <th>Удалить</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            data.map((el) => {
                                                return (
                                                    <tr key={el.id}>
                                                        <td><img width={50} height={50} src={el.work_img} alt=""/></td>
                                                        <td>{el.work_name}</td>
                                                        <td>{el.work_technology}</td>
                                                        <td><a href={el.work_link}>{el.work_link}</a></td>
                                                        <td>
                                                            <button onClick={() => handleDeleteWork(el.id)}><BiSolidTrash/></button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </>

                                    : <div className={style.empty_work}>Нет добавленных работ!</div>
                                }
                            </table>
                        }
                        {addNewWork &&
                            <div className={style.add_new_work}>
                                <div className={style.form_header}>Добавить работу</div>
                                <div className={style.form_group}>
                                    <label htmlFor="name">Название:</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={name}
                                        required
                                        onChange={(e) => dispatch(Name(e.target.value))}/>
                                </div>
                                <div className={style.form_group}>
                                    <label htmlFor="text">Технологии:</label>
                                    <input type="text" id="text" name="text"
                                           value={technology}
                                           onChange={(e) => dispatch(Technology(e.target.value))}/>
                                </div>
                                <div className={style.form_group}>
                                    <label htmlFor="text">Ссылка:</label>
                                    <input type="text" id="text" name="text"
                                           value={link}
                                           onChange={(e) => dispatch(LinkWork(e.target.value))}/>
                                </div>
                                <ImageUploader/>
                                <button className={style.submit_button} onClick={handleAddWork}>Добавить</button>
                            </div>
                        }
                        {changeData &&
                            <div className={style.add_new_work}>
                                <div className={style.form_header}>Сменить логин и пароль</div>
                                <div className={style.form_group}>
                                    <label htmlFor="oldLogin">Старый логин:</label>
                                    <input
                                        type="text"
                                        id="oldLogin"
                                        name="oldLogin"
                                        value={oldLogin}
                                        onChange={(e) => dispatch(OldLogin(e.target.value))}/>
                                </div>
                                <div className={style.form_group}>
                                    <label htmlFor="login">Новый логин:</label>
                                    <input
                                        type="text"
                                        id="login"
                                        name="login"
                                        value={changeLogin}
                                        required
                                        onChange={(e) => dispatch(ChangeLogin(e.target.value))}/>
                                </div>
                                <div className={style.form_group}>
                                    <label htmlFor="password">Новый пароль:</label>
                                    <input type="password"
                                           id="password"
                                           name="password"
                                           value={changePassword}
                                           onChange={(e) => dispatch(ChangePassword(e.target.value))}/>
                                </div>
                                {isSuccesSend && <Notification notification={"Логин и пароль успешно изменено!"} bgColor="2fb500"/> }
                                {isErrorSend && <Notification notification={"Заполните все поля!"} bgColor="d50202"/> }
                                {isErrorOldLoginSend && <Notification notification={"Старый пароль не верный!"} bgColor="d50202"/> }
                                <button className={style.submit_button} style={{marginTop: "15px"}} onClick={handelChangeAdminSignIn}>Смениить</button>
                            </div>
                        }
                        {skills &&
                            <div className={style.add_new_work}>
                                <div className={style.form_header}>Добавить скил</div>
                                <div className={style.form_group}>
                                    <label htmlFor="name">Ссылка:</label>
                                    <input
                                        type="text"
                                        id="alt"
                                        name="alt"
                                        value={imgAlt}
                                        required
                                        onChange={(e) => dispatch(ImgAlt(e.target.value))}/>
                                </div>
                                <div className={style.form_group}>
                                    <label htmlFor="name">Alt картинки:</label>
                                    <input
                                        type="text"
                                        id="skills"
                                        name="skills"
                                        value={skillsSource}
                                        required
                                        onChange={(e) => dispatch(SkillsSource(e.target.value))}/>
                                </div>
                                <ImageUploader/>
                                <button className={style.submit_button} onClick={handleSkill}>Добавить</button>
                            </div>
                        }
                    </div>
                </>
                :
                <PasswordInAdmin/>
            }
        </div>
    );
}

export default Admin;