import style from "./Logo.module.scss";
import santaHat from "../../assets/images/santa-hat.png"
import SnowInText from "../snow/SnowInText";
import {useSelector} from "react-redux";
export default function Logo() {
    const isSnow = useSelector(state => state.snow.isSnow)
    return (
        <a href="/" className={style.logo}>
            {isSnow &&
                <>
                    <SnowInText position="center" height={11} top={11} left={-2} resHeight={11}/>
                    <img src={santaHat} className={style.santa_hat} alt=""/>
                </>
            }
            <span>Namra</span>
        </a>
    )
}