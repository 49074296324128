import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
    reducerPath: "api",
    tagTypes: ["Works", "TechnicalWorks", "Snow", "Skills"],
    baseQuery: fetchBaseQuery({ baseUrl: 'http://localhost:3001/' }),
    endpoints: (builder) => ({
        addData: builder.mutation({
            query: (data) => ({
                url: 'addWork',
                method: "POST",
                body: data,
            }),
            invalidatesTags: [`Works`],
        }),
        fetchData: builder.query({
            query: () => ({
                url: "fetchWorks",
            }),
            providesTags: result => [`Works`],
        }),
        deleteData: builder.mutation({
            query: (data) => ({
                url: 'deleteWork',
                method: "POST",
                body: data
            }),
            invalidatesTags: [`Works`],
        }),
        DataChecking: builder.query({
            query: (data) => ({
                url: 'dataChecking',
            })
        }),
        ChangeLoginData: builder.mutation({
            query:(data) => ({
                url: 'changeLoginData',
                method: "POST",
                body: data
            })
        }),
        IsSnow: builder.mutation({
            query:(data) => ({
                url: 'isSnow',
                method: "POST",
                body: data
            }),
            invalidatesTags: [`Snow`],
        }),
        getIsSnow: builder.query({
            query: (data) => ({
                url: 'getIsSnow',
            }),
            providesTags: result => [`Snow`],
        }),
        getSkills: builder.query({
            query:() => ({
                url: 'getSkills'
            }),
            providesTags: result => [`Skills`]
        }),
        addSkills: builder.mutation({
            query:(data) => ({
                url: 'addSkills',
                method: "POST",
                body: data
            }),
            invalidatesTags: [`Skills`]
        }),
    }),
});
export const {
    useAddDataMutation,
    useFetchDataQuery,
    useDeleteDataMutation,
    useDataCheckingQuery,
    useChangeLoginDataMutation,
    useGetIsSnowQuery,
    useIsSnowMutation,
    useGetSkillsQuery,
    useAddSkillsMutation,
} = api;
