import React from 'react';
import style from "./Work.module.scss"
import {useTranslation} from "react-i18next";
import SnowInText from "../snow/SnowInText";
import img from "../../assets/images/work.png"
import {useSelector} from "react-redux";
function Work(props) {
    const {work_name, work_technology, work_link, id, work_img} = props
    const { t } = useTranslation();
    const isSnow = useSelector(state => state.snow.isSnow)

    return (
        <div key={id} className={style.work}>
            {isSnow && <SnowInText zIndex={10} top={-9} position="bottom" height={108} left={0} resHeight={65}/>}
            <div className={style.work_img}><img src={work_img} alt="work"/></div>
            <div className={style.work_content}>
                <div className="work_name">{work_name}</div>
                <div className="work_subtitle">{work_technology}</div>
                <a href={work_link} target="_blank" className="button"><span>{t("View")}</span></a>
            </div>
        </div>
    );
}
export default Work;