import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    name: "",
    technology: "",
    image: "",
    link: "",
    login: "",
    password: "",
    isLock: false,
    oldLogin: "",
    changeLogin: "",
    changePassword: "",
    skillsSource: "",
    imgAlt: "",
}
const adminSlice = createSlice({
    name: 'adminSlice',
    initialState,
    reducers: {
        Name(state, action){
            state.name = action.payload
        },
        Technology(state, action){
            state.technology = action.payload
        },
        WorkImage(state, action){
            state.image = action.payload
        },
        LinkWork(state, action){
            state.link = action.payload
        },
        Login(state, action){
            state.login = action.payload
        },
        Password(state, action){
            state.password = action.payload
        },
        IsLock(state, action){
            state.isLock = action.payload
        },
        ChangeLogin(state, action){
            state.changeLogin = action.payload
        },
        ChangePassword(state, action){
            state.changePassword = action.payload
        },
        OldLogin(state, action){
            state.oldLogin = action.payload
        },
        SkillsSource(state, action) {
          state.skillsSource = action.payload
        },
        ImgAlt(state, action) {
          state.imgAlt = action.payload
        },
    },
});

export default adminSlice.reducer;

export const {
    Name,
    Technology,
    WorkImage,
    LinkWork,
    Login,
    Password,
    IsLock,
    ChangeLogin,
    ChangePassword,
    OldLogin,
    SkillsSource,
    ImgAlt,
} = adminSlice.actions