import React, {useEffect} from 'react';
import style from "./Header.module.scss"
import {useTranslation} from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
function Header() {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    return (
        <div className={style.header} id="about-me">
            <div className="container">
                <div className={style.header_content} data-aos="fade-up">
                    <div className="subtitle">{t("FULL STACK PROGRAMMER")}</div>
                    <div className={style.bigTitle}>{t("Arman Tepnants")}</div>
                    <div className="subtitle">
                        {t("Welcome to my portfolio page. I am a Full-Stack developer with experience in building" +
                            " modern web applications. My passion is to create interactive and satisfying web interfaces using cutting edge technologies.")}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Header;