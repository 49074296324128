import React, {useEffect} from 'react';
import style from "./Skills.module.scss"
import Title from "../title/Title";
import AOS from "aos";
import 'aos/dist/aos.css';
import {useGetSkillsQuery} from "../../redux/rtkquery/api"; // Импорт стилей AOS
function Skills() {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Продолжительность анимации в миллисекундах
        });
    }, []);
    const {data= []} = useGetSkillsQuery()

    return (
        <section id="skills">
            <div className="container">
                <Title title={"Skills"}/>
                <div className={style.skills_items}>
                    {data &&
                        data.map((el) => {
                            return (<div data-aos="fade-right" className={style.skills_item}><a
                                 href={el.link_source} target="_blank"><img src={el.link_image} alt={el.img_alt + "-logo"}/></a></div>)
                        })
                    }
                </div>
            </div>
        </section>
    );
}

export default Skills;