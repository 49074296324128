import React, {useState} from 'react';
import style from "./Language.module.scss";
import russiaFlag from "../../assets/images/russia-flag.png";
import usaFlag from "../../assets/images/usa-flag.png";
import i18n from "i18next";
import useLocalStorage from "../../hooks/use-localstorage";

function Languages() {
    const [openLangFlags, setOpenLangFlags] = useState(false)
    const [language, setLanguage] = useLocalStorage('language', 'en')

    const handleOpenFlags = () => {
        setOpenLangFlags(!openLangFlags)
    }
    const handleLanguageChange = (l) => {
        if (language === "en") {
            i18n.changeLanguage(l)
            setLanguage(l)
        } else if (language === "ru") {
            i18n.changeLanguage(l)
            setLanguage(l)
        }
    }
    return (
        <div className={style.select_lang}>
            <div className={style.lang_flag} onClick={handleOpenFlags}><img
                src={language === "ru" ? russiaFlag : usaFlag} alt="language-flag"/></div>
            <div className={openLangFlags ? style.show + " " + style.flags_body : style.flags_body}>
                <div className={style.lang_flag} onClick={() => {
                    handleLanguageChange(language === "ru" ? "en" : "ru");
                    setOpenLangFlags(false)
                }}><img src={language === "ru" ? usaFlag : russiaFlag} alt={language === "en" ? "Russian" : "English"}/>
                </div>
            </div>
        </div>
    );
}
export default Languages;