import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from '././langs/en.json'; // Подключите ваш файл с переводами для английского
import ruTranslation from '././langs/ru.json'; // Подключите ваш файл с переводами для испанского
i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: enTranslation,
            },
            ru: {
                translation: ruTranslation,
            },
        },
        lng: JSON.parse(localStorage.getItem('language')),
        fallbackLng: 'ru',
        interpolation: {
            escapeValue: false,
        },
    });
export default i18n;