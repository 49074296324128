import React from 'react';
import Head from "../components/head/Head";
import Header from "../components/header/Header";
import MyWorks from "../components/my_works/MyWorks";
import Skills from "../components/skills/Skills";
import Footer from "../components/footer/Footer";
import {useSelector} from "react-redux";
function Home(props) {
    const isSnow = useSelector(state => state.snow.isSnow)

    return (
        <div className="home" style={{paddingBottom: isSnow ? "127px" : 0}}>
            <header>
                <Head/>
                <Header/>
            </header>
            <main>
                <MyWorks/>
                <Skills/>
            </main>
            <footer>
                <Footer/>
            </footer>
        </div>
    );
}

export default Home;