import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import AOS from "aos";
import 'aos/dist/aos.css'; // Импорт стилей AOS
export default function Title({title}){
    useEffect(() => {
        AOS.init({
            duration: 1000, // Продолжительность анимации в миллисекундах
        });
    }, []);
    const { t } = useTranslation();
    return <div data-aos="zoom-in" className="sectionTitle">{t(title)}.</div>
}