import React, {useEffect, useState} from 'react';
import style from "./MyWorks.module.scss"
import Work from "../work-template/Work";
import {useFetchDataQuery} from "../../redux/rtkquery/api";
import Title from "../title/Title";
import AOS from "aos";
import 'aos/dist/aos.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function MyWorks() {
    const {data = []} = useFetchDataQuery()

    useEffect(() => {
        AOS.init({
            duration: 1000, // Продолжительность анимации в миллисекундах
        });
    }, []);

    return (
        <section className={style.my_works} id="my-works">
            <div className="container">
                <Title title={"My Works"}/>
                <div className={style.works} data-aos="fade-up">
                {
                    data.map((el)=>{
                        return <Work key={el.id} {...el}/>
                    })
                }
                </div>
            </div>
        </section>
    );
}

export default MyWorks;