import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Admin from "./admin/Admin";
import "./App.scss"
import {useTranslation} from "react-i18next";
import Snowfall from "react-snowfall";
import SnowInGround from "./components/snow-in-ground/SnowInGround";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useGetIsSnowQuery} from "./redux/rtkquery/api";
import {Snow} from "./redux/snowSlice";
function App() {
    const thisLang = useTranslation().i18n.language
    const isSnow = useSelector(state => state.snow.isSnow)
    const dispatch = useDispatch()
    const {data=[]} = useGetIsSnowQuery()
    const snow = data.length && data[0].isSnow
    useEffect(() => {
        document.documentElement.lang = thisLang;
        if (thisLang === 'ru') {
            document.title = 'Namra - Портфолио';
            const metaTag = document.querySelector('meta[name="description"]');
            if (metaTag) {
                metaTag.setAttribute('content', 'Добро пожаловать в мое портфолио Full Stack веб-разработчика! Ознакомьтесь с моими проектами и навыками создания современных и инновационных веб-приложений.');
            }
        } else if (thisLang === 'en') {
            document.title = 'Namra - Portfolio';
            const metaTag = document.querySelector('meta[name="description"]');
            if (metaTag) {
                metaTag.setAttribute('content', 'Welcome to my Full Stack Web Developer portfolio! Check out my projects and skills in creating modern and innovative web applications.');
            }
        }
    }, [thisLang]);
    useEffect(()=>{
        if(snow === 1){
            dispatch(Snow(true))
        }else {
            dispatch(Snow(false))
        }
    }, [data])
    return (
        <div className="App">
            {isSnow && <Snowfall
                snowflakeCount={450}
                color="#fff"
                snowflakeSize={25}
                snowflakePosition="top"
                style={{ zIndex: 105 }}
            />}
            <Routes>
                <Route path="/admin" element={<Admin/>}/>
                <Route path="/" element={<Home />} />
            </Routes>
            {isSnow && <SnowInGround/>}
        </div>
    );
}
export default App;