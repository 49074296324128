import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {WorkImage} from "../../redux/adminSlice";

const ImageUploader = () => {
    const [imageObj, setImageObj] = useState(null);
    const dispatch = useDispatch()
    const image = useSelector(state => state.adminSlice.image)
    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];
        setImageObj(selectedImage);
    };

    const uploadImage = async () => {
        try {
            if (imageObj) {
                const myHeaders = new Headers();
                myHeaders.append("Authorization", "Client-ID 3021a60e7c41206");

                const formData = new FormData();
                formData.append("image", imageObj);

                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formData,
                    redirect: 'follow'
                };

                const response = await fetch("https://api.imgur.com/3/image", requestOptions);
                const data = await response.json();

                if (response.ok) {
                    dispatch(WorkImage(data.data.link))
                } else {
                    console.error('Не удалось загрузить изображение. Статус:', response.status);
                }
            } else {
                console.error('Не выбрано изображение для загрузки');
            }
        } catch (error) {
            console.error('Ошибка при загрузке изображения:', error);
        }
    };

    return (
        <div>
            <input type="file" onChange={handleImageChange}/>
            <img src={image} alt="image"/>
            <button onClick={uploadImage}>Загрузить изображение</button>
        </div>
    );
};

export default ImageUploader;
