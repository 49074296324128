import React from 'react';
import style from "./PasswordInAdmin.module.scss"
import {useDispatch, useSelector} from "react-redux";
import {IsLock, Login, Password} from "../../redux/adminSlice";
import {useDataCheckingQuery} from "../../redux/rtkquery/api";
import Notification from "../notification/Notification";
function PasswordInAdmin(props) {
    const {login, password} = useSelector(state => state.adminSlice)
    const dispatch = useDispatch()
    const {data} = useDataCheckingQuery()

    const handleSend = () => {
        const dbLogin = data !== null && data[0].login
        const dbPassword = data !== null && data[0].password
        if(data && login === dbLogin && password === dbPassword){
            dispatch(IsLock(true))
        }else {
            alert("Ошибка!")
        }
    }
    return (
        <div className="container">
            <div className={style.password_form}>
                <div className={style.password_form_title}>Введите данные для входа</div>
                <div className={style.password_form_inputs}>
                    <label htmlFor="login">
                        <span>Логин</span>
                        <input
                            type="text"
                            placeholder="Логин"
                            id="login"
                            onChange={(e) => dispatch(Login(e.target.value))}
                            value={login}
                        />
                    </label>
                    <label htmlFor="password">
                        <span>Пароль</span>
                        <input
                            type="password"
                            placeholder="Пароль"
                            id="password"
                            onChange={(e) => dispatch(Password(e.target.value))}
                            value={password}
                        />
                    </label>
                    <button className="button" onClick={handleSend}><span>Войти</span></button>
                </div>
            </div>
        </div>
    );
}

export default PasswordInAdmin;