import React, {useEffect, useState} from 'react';
import style from "./Footer.module.scss"
import Logo from "../logo/Logo";
import Link from "../link/Link";
import {FaFacebook, FaInstagram, FaTelegram, FaViber, FaWhatsapp} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import AOS from "aos";
import 'aos/dist/aos.css';
import Modal from "../modal/Modal";
import DarkBg from "../dark-bg/DarkBG"; // Импорт стилей AOS
function Footer() {
    const date = new Date()
    const year = date.getFullYear();
    const [modalOpen, setModalOpen] = useState(false)
    const handleModalOpen = () => {
        setModalOpen(true)
    }
    const handleModalClose = () => {
        setModalOpen(false)
    }
    const { t } = useTranslation();
    return (
        <div className={style.footer} id="contacts">
            {modalOpen &&
                <>
                    <Modal/>
                    <DarkBg func={handleModalClose}/>
                </>
            }
            <div className="container">
                <div className={style.footer_content}>
                    <div className={style.footer_right}>
                        <Logo/>
                        <div className={style.subtitle + " subtitle"}>{t("Full Stack Developers Crafting the Digital World")}.</div>
                    </div>
                    <div className={style.footer_left}>
                        <Link linkText={t("About me")} sectionID="about-me"/>
                        <Link linkText={t("Works")} sectionID="my-works"/>
                        <Link linkText={t("Skills")} sectionID="skills"/>
                        <Link linkText={t("Contacts")} sectionID="contacts"/>
                    </div>
                    <div className={style.feedback}>
                        <button className="button" onClick={handleModalOpen}><span>{t("Сontact with me")}</span></button>
                        <div className={style.mail}><a href="mailto:armantepnanc@gmail.com">armantepnanc@gmail.com</a></div>
                    </div>
                </div>
                <div className={style.footer_copyRight} >
                    <div>© {year} Namra.ru - {t("portfolio")}.</div>
                    <div className={style.socials}>
                        <a href="https://wa.me/qr/QTBBXM7QZDOTO1"><FaWhatsapp/></a>
                        <a href="https://viber.click/37477101556"><FaViber/></a>
                        <a href="https://t.me/armtep"><FaTelegram/></a>
                        <a href="https://www.instagram.com/arm.tepnanc/"><FaInstagram/></a>
                        <a href="https://m.me/arman.tepnanc.5"><FaFacebook/></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;